.ck.ck-editor__main {
  border-radius: 5px;
  -webkit-user-select: text;
}
.ck-restricted-editing_mode_standard.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  border-radius: 5px;
  padding: 8px !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.ck-restricted-editing_mode_restricted.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  border-radius: 5px;
  padding: 8px !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.ck-restricted-editing_mode_restricted.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  caret-color: transparent;
  padding: 8px !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.ck-restricted-editing_mode_standard.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  padding: 8px !important;
}
.ck-restricted-editing_mode_restricted.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused
  .restricted-editing-exception {
  caret-color: auto;
  padding: 8px !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.ck-restricted-editing_mode_restricted.ck-editor__editable {
  padding: 8px !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.ck.ck-editor .ck.ck-editor__top {
  animation-duration: 0.3s;
  border-radius: 5px !important;
  border-bottom: none !important;
  max-height: 0;
  opacity: 0;
  transition: all 0.4s ease;
  overflow: initial !important;
  pointer-events: none !important;
}
.ck.ck-editor .ck.ck-content {
  overflow: initial;
  float: left;
  width: 100%;
}

.ck.ck-editor .ck.ck-content::after {
  content: '.';
  height: 0;
  width: 0;
  overflow: hidden;
  clear: both;
  display: block;
}

.ck.ck-editor .ck .ck-widget {
  display: inline-block;
  margin-top: 2px;
}
.ck.ck-editor .ck-content .image {
  display: block !important;
}

.ck.ck-editor .ck-content .media {
  display: block !important;
}

.ck.ck-editor .ck-editor__nested-editable {
  border: 0;
}

.ck-content .table table td,
.ck-content .table table th {
  border: 1px solid #bfbfbf !important;
}

.ck-editor__editable:not(.ck-read-only)
  .ck-widget_selected
  .ck-media__wrapper
  > :not(.ck-media__placeholder) {
  pointer-events: initial !important;
}
