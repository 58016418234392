.error {
  color: red;
  font-weight: bold;
}

.controls {
  font-size: 12px;
  font-weight: bold;
}

.controls > * {
  cursor: pointer;
  margin-right: 8px !important;
  text-transform: none !important;
}
