.editor-stub {
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  min-height: 52px;
  color: #000000;
  font-size: 14px;
  line-height: 1.4;
  font-family: 'Arial', 'HelveticaNeueCyr', sans-serif;
  --ck-spacing-unit: 0.6em;
  --ck-spacing-standard: var(--ck-spacing-unit);
  --ck-spacing-large: calc(var(--ck-spacing-unit) * 1.5);
  padding: 0 var(--ck-spacing-standard);
}

.editor-stub .content {
  /*margin-bottom: var(--ck-spacing-large);*/
  /*margin-top: var(--ck-spacing-large);*/
  /*display: inline-block;*/
  /*box-sizing: border-box;*/
  overflow: initial;
  float: left;
  width: 100%;
}

/*Text size*/
@media (min-width: 768px) {
  .editor-stub .content .text-huge {
    font-size: 1.8em;
  }
}

@media (min-width: 768px) {
  .editor-stub .content .text-big {
    font-size: 1.4em;
  }
}

@media (min-width: 768px) {
  .editor-stub .content .text-small {
    font-size: 0.85em;
  }
}

@media (min-width: 768px) {
  .editor-stub .content .text-tiny {
    font-size: 0.7em;
  }
}

/*Table*/
/* .editor-stub .content p {
  overflow-x: auto;
  overflow-y: hidden;
} */
.editor-stub .content .editable-field {
  display: inline-block;
}
.editor-stub .content .table {
  margin: 2px 0;
  display: inline-block;
}
.editor-stub .content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double #b3b3b3;
}
.editor-stub .content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid #bfbfbf;
}
.editor-stub .content .table table th {
  font-weight: bold;
  background: rgba(0, 0, 0, 5);
}
.editor-stub .content[dir='rtl'] .table th {
  text-align: right;
}

.editor-stub .content[dir='ltr'] .table th {
  text-align: left;
}
.editor-stub .content .ck-table-bogus-paragraph {
  display: inline-block;
  width: 100%;
}

/*ToDoList*/
.editor-stub .content.todo-list {
  --ck-todo-list-checkmark-size: 16px;
  list-style: none;
}
.editor-stub .content .todo-list li {
  margin-bottom: 5px;
}
.editor-stub .content .todo-list li::marker {
  content: '';
}
.editor-stub .content .todo-list li .todo-list {
  margin-top: 5px;
}
.editor-stub .content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
.editor-stub .content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid #333;
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}
.editor-stub .content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: '';
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}
.editor-stub .content .todo-list .todo-list__label > input[checked]::before {
  background: #26ab33;
  border-color: #26ab33;
}
.editor-stub .content .todo-list .todo-list__label > input[checked]::after {
  border-color: #fff;
}
.editor-stub .content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
[dir='rtl'] .todo-list .todo-list__label > input {
  left: 0;
  margin-right: 0;
  right: -25px;
  margin-left: -15px;
}
.editor-stub .content .todo-list .todo-list__label > input {
  cursor: pointer;
}
.editor-stub .content .todo-list .todo-list__label > input:hover::before {
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
}

/*Insert Code*/
.editor-stub .content pre {
  background: #c7c7c74d;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  color: #353535;
  direction: ltr;
  font-style: normal;
  min-width: 200px;
  padding: 1em;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
}

/*Block Qoute*/
.editor-stub .content blockquote {
  border-left: 5px solid #ccc;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.ck .ck-widget:hover {
  outline-color: var(--ck-color-widget-hover-border);
}

/*Editable Fields*/
:root {
  --ck-editable-field-bg: hsl(160, 66%, 48%, 0.2);
  --ck-editable-field-review-accept: hsl(153, 49%, 59%);
  --ck-editable-field-review-reject: hsl(7, 43%, 62%);
  --ck-editable-field-review-bg: hsl(49, 95%, 92%);
  --ck-editable-field-review-color: hsl(6, 44%, 63%);
  --ck-editable-field-review-border: hsl(0, 0%, 84%);
  --ck-editable-field-review-bg-hover: hsl(0, 0%, 98%);
  --ck-editable-field-review-color-сurrent: hsl(180, 0%, 45%);
  --ck-editable-field-tooltip-border-color: hsl(0, 0%, 60%);
  --ck-editable-field-tooltip-text-color: hsl(220, 13%, 18%);
  --ck-editable-field-tooltip-bg: hsl(0, 100%, 100%);
  --ck-color-widget-hover-border: hsl(43, 100%, 62%);
}

.editable-field {
  position: relative;
  background-color: var(--ck-editable-field-bg);
  padding: 1px 3px 2px;
  display: inline-block;
  margin-top: 2px;
  margin-left: 2px;
  min-width: 80px;
  min-height: 20px;
  word-break: break-all;
}

.editable-field-title {
  min-width: 80px;
  display: inline-block;
  z-index: 2;
}

.editable-field.ck-widget {
  display: inline-block;
  margin-top: 2px;
  margin-left: 2px;
}

.editable-field.ck-widget:hover {
  outline: 3px solid var(--ck-color-widget-hover-border);
}

.editable-field-buttons {
  position: absolute;
  top: 0;
  left: 50%;
  padding-bottom: 4px;
  transform: translate(-50%, -100%);
  white-space: nowrap;
}

.editable-field-disabled {
  pointer-events: none;
}

.editable-field .editable-field-buttons {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
}

.editable-field:hover .editable-field-buttons {
  opacity: 1;
  pointer-events: initial;
}

.editable-field-buttons .btn {
  border-radius: 0;
  cursor: pointer;
  background-color: var(--ck-editable-field-tooltip-bg);
  border: solid 1px var(--ck-editable-field-review-border);
  font-size: 14px;
  line-height: 1;
  min-width: 80px;
  outline: none;
  padding: 9px 12px;
}

.editable-field-buttons .btn:hover {
  background-color: var(--ck-editable-field-review-bg-hover);
}

.editable-field-buttons .btn-accept {
  color: var(--ck-editable-field-review-accept);
  border-radius: 7px 0 0 7px;
  border-right: none;
}

.editable-field-buttons .btn-reject {
  color: var(--ck-editable-field-review-reject);
  border-radius: 0 7px 7px 0;
}

.editable-field[data-updating='true'] .editable-field-title {
  width: 0;
  height: 0;
  overflow: hidden;
}

.editable-field[data-updating='true'] .editable-field-title::before {
  content: attr(data-updating-text);
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
}

.editable-field[data-new-value] {
  background-color: var(--ck-editable-field-review-bg);
  min-width: 80px;
}
.editable-field[data-new-value] .editable-field-title {
  pointer-events: none;
  color: var(--ck-editable-field-review-color);
  text-decoration: line-through;
  min-width: auto;
  padding-right: 4px;
}
.editable-field[data-new-value]::after {
  content: attr(data-new-value);
  color: var(--ck-editable-field-review-color-сurrent);
}

.editable-field::before {
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.3s;
  opacity: 0;
  content: 'Field name: ' attr(data-name);
  position: absolute;
  bottom: -3px;
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 0;
  font-style: italic;
  border: 1px solid var(--ck-editable-field-tooltip-border-color);
  color: var(--ck-editable-field-tooltip-text-color);
  padding: 0 4px;
  border-radius: 4px;
  background-color: var(--ck-editable-field-tooltip-bg);
  font-size: 12px;
  transform: translateY(100%);
}

.editable-field:hover::before {
  opacity: 1;
}

.editable-field .restricted-editing-exception {
  background: transparent !important;
  border: none !important;
}

.editor-stub .content .image {
  position: relative;
  clear: both;
  display: block !important;
  margin: 0.9em auto;
  min-width: 50px;
  text-align: center;
}

.editor-stub .content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}

.editor-stub .content .image.image_resized {
  box-sizing: border-box;
  display: block;
  max-width: 100%;
}

.editor-stub .content .image.image_resized img {
  width: 100%;
}

.editor-stub .content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}

.editor-stub .content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
