.container {
  width: fit-content;
}

.signatureImage {
  max-height: 48px;
}

.actions {
  margin-left: 12px;
}
