/*
TODO authV2:
  - remove style
  - #signup-form  svg {} style move to variants/forms.tsx
*/

#signup-form .c-checkbox__link--dark {
  color: #000;
  text-decoration: underline;
}
