// media
//-----------------------------------------------------------
.max-767 {
  @media (min-width: 768px) {
    display: none;
  }
}

.min-768 {
  @media (max-width: 767px) {
    display: none;
  }
}

.max-959 {
  @media (min-width: 960px) {
    display: none;
  }
}

.min-960 {
  @media (max-width: 959px) {
    display: none;
  }
}

.max-1279 {
  @media (min-width: 1280px) {
    display: none;
  }
}

.min-1280 {
  @media (max-width: 1279px) {
    display: none;
  }
}

.max-767-visibility {
  @media (min-width: 768px) {
    visibility: hidden;
  }
}

.min-768-visibility {
  @media (max-width: 767px) {
    visibility: hidden;
  }
}

.max-959-visibility {
  @media (min-width: 960px) {
    visibility: hidden;
  }
}

.min-960-visibility {
  @media (max-width: 959px) {
    visibility: hidden;
  }
}

.max-1279-visibility {
  @media (min-width: 1280px) {
    visibility: hidden;
  }
}

.min-1280-visibility {
  @media (max-width: 1279px) {
    visibility: hidden;
  }
}

// flexbox
//-----------------------------------------------------------
.h-flex {
  display: flex;
}

.h-flex-none {
  flex: none;
}

.h-flex-inline {
  display: inline-flex;
}

.h-direction-row {
  flex-direction: row;
}

.h-direction-column {
  flex-direction: column;
}

.h-align-end {
  align-items: flex-end;
}

.h-align-start {
  align-items: flex-start;
}

.h-align-center {
  align-items: center;
}

.h-align-baseline {
  align-items: baseline;
}

.h-justify-end {
  justify-content: flex-end;
}

.h-justify-start {
  justify-content: flex-start;
}

.h-justify-center {
  justify-content: flex-center;
}

.h-self-end {
  align-self: flex-end;
}

.h-self-start {
  align-self: flex-start;
}

.h-self-center {
  align-self: flex-center;
}

// typography
//-----------------------------------------------------------
.h-fs-10 {
  font-size: 10px;
}

.h-fs-12 {
  font-size: 12px;
}

.h-fs-14 {
  font-size: 14px;
}

.h-fs-16 {
  font-size: 16px;
}

.h-fw-400 {
  font-weight: 400;
}

.h-fw-600 {
  font-weight: 600;
}

.h-uppercase {
  text-transform: uppercase;
}

.h-lowercase {
  text-transform: lowercase;
}

.h-capitalize {
  text-transform: capitalize;
}

// gaps
//-----------------------------------------------------------
.h-mt-0 {
  margin-top: 0;
}

.h-mt-4 {
  margin-top: 4px;
}

.h-mt-8 {
  margin-top: 8px;
}

.h-mt-16 {
  margin-top: 16px;
}

.h-mt-32 {
  margin-top: 32px;
}

.h-ml-0 {
  margin-left: 0;
}

.h-ml-4 {
  margin-left: 4px;
}

.h-ml-8 {
  margin-left: 8px;
}

.h-ml-16 {
  margin-left: 16px;
}

.h-ml-32 {
  margin-left: 32px;
}

.h-mr-0 {
  margin-right: 0;
}

.h-mr-4 {
  margin-right: 4px;
}

.h-mr-8 {
  margin-right: 8px;
}

.h-mr-16 {
  margin-right: 16px;
}

.h-mr-32 {
  margin-right: 32px;
}

.h-mb-0 {
  margin-bottom: 0;
}

.h-mb-4 {
  margin-bottom: 4px;
}

.h-mb-8 {
  margin-bottom: 8px;
}

.h-mb-16 {
  margin-bottom: 16px;
}

.h-mb-32 {
  margin-bottom: 32px;
}

.h-pt-0 {
  padding-top: 0;
}

.h-pt-4 {
  padding-top: 4px;
}

.h-pt-8 {
  padding-top: 8px;
}

.h-pt-16 {
  padding-top: 16px;
}

.h-pt-32 {
  padding-top: 32px;
}

.h-pl-0 {
  padding-left: 0;
}

.h-pl-4 {
  padding-left: 4px;
}

.h-pl-8 {
  padding-left: 8px;
}

.h-pl-16 {
  padding-left: 16px;
}

.h-pl-32 {
  padding-left: 32px;
}

.h-pr-0 {
  padding-right: 0;
}

.h-pr-4 {
  padding-right: 4px;
}

.h-pr-8 {
  padding-right: 8px;
}

.h-pr-16 {
  padding-right: 16px;
}

.h-pr-32 {
  padding-right: 32px;
}

.h-pb-0 {
  padding-bottom: 0;
}

.h-pb-4 {
  padding-bottom: 4px;
}

.h-pb-8 {
  padding-bottom: 8px;
}

.h-pb-16 {
  padding-bottom: 16px;
}

.h-pb-32 {
  padding-bottom: 32px;
}
