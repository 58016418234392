.captureBox {
  width: 100%;
  margin-top: 20px;
  height: 80px;
  transition: opacity 1s, height 0.3s ease-out;
  display: flex;
}

.hide {
  visibility: hidden;
  opacity: 0;
  height: 0;
  margin-top: 0;
}
