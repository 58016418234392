/* .l-app
-------------------------------------------------------------*/
$r: '.l-app';

// base
//-----------------------------------------------------------
#{$r} {
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  scroll-behavior: smooth;

  @media (min-width: 960px) {
    position: fixed;
  }

  // modifiers
  //---------------------------------------------------------
  &--preview {
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &--shadow {
    overflow: hidden;
    // position: relative;

    &:before {
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 8;
      content: '';
      height: 100%;
      position: fixed;
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 70.36%,
        #ffffff 96.25%
      );
    }
  }
}

// influence
//-----------------------------------------------------------
