/* .c-letter
-------------------------------------------------------------*/
$r: '.c-letter';

// block
//-----------------------------------------------------------
#{$r} {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  // element
  //---------------------------------------------------------
  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    > * {
      flex-grow: 1;
    }
  }

  &__section {
    flex-grow: 1;
    padding: $size * 2;

    @media (min-width: 960px) {
      margin-top: 0;
    }

    figure {
      max-width: 200px;
      width: auto !important;
    }
  }

  &__block {
    color: $gray-dark;
    line-height: 1.6;
    padding: $size * 2;
    margin-top: $size * 2;
    border-radius: $size * 2;

    @media (min-width: 960px) {
      font-size: $size * 2;
    }

    &:first-child {
      margin-top: 0;
    }

    &:nth-child(odd) {
      background-color: $white;
    }

    &:nth-child(even) {
      background-color: lighten($gray-light, 4%);
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    margin-top: $size * 4;
    position: relative;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    &--buttons {
      display: flex;
      flex-direction: column;

      > * {
        margin-top: $size * 2;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &--text {
      color: $theme-color;
      font-size: $size * 2;
      font-weight: 600;

      @media (max-width: 767px) {
        order: -1;
        text-align: center;
        margin-bottom: $size * 2;
      }

      @media (min-width: 768px) {
        font-size: $size * 3;
        padding-left: $size * 2;
      }

      @media (min-width: 1280px) {
        font-size: $size * 4;
      }
    }

    > #{$r}__column {
      flex-grow: 1;
      min-width: 30%;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    margin-top: $size * 3;

    @media (min-width: 768px) {
      flex-direction: row;
      margin-top: $size * 3;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__column {
    @media (max-width: 767px) {
      margin-top: $size * 3;

      &:first-child {
        margin-top: 0;
      }
    }

    @media (min-width: 768px) {
      padding-right: $size * 4;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-top: $size;

    &:first-child {
      margin-top: 0;
    }
  }

  &__check {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: $size * 3;
    height: $size * 3;
    margin-right: $size;
    border-radius: 100%;
    background-color: $green;

    &:before {
      content: '';
      width: $size - 2px;
      height: $size * 2 - 4px;
      margin-top: -$size / 4;
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      transform: rotate(45deg);
    }
  }

  &__cross {
    width: $size * 3;
    height: $size * 3;
    margin-right: $size;
    position: relative;
    transition: 0.3s;

    &:after,
    &:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      margin: auto;
      position: absolute;
      height: 2px;
      background-color: $red;
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }
  }

  &__agreement {
    display: flex;
    font-size: 12px;
    color: $gray-pale;
    line-height: 1.6;

    .icon {
      height: $size * 7;
      min-width: $size * 7;
      margin-right: $size * 2;
    }
  }

  &__wish {
    &--title {
      color: $theme-color;
      font-size: $size * 2;
      font-weight: 600;
      margin-bottom: $size * 2;

      @media (min-width: 1280px) {
        font-size: $size * 3;
      }
    }
  }

  &__sign {
    display: flex;
    align-items: center;
  }

  &__avatar {
    height: $size * 6;
    min-width: $size * 6;
    max-width: $size * 6;
    border-radius: 100%;
    border: 1px solid $green;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  &__signature {
    display: flex;
    align-items: center;
    margin-left: $size * 2;
    min-height: $size * 5 + 4px;

    img {
      max-height: $size * 5 + 4px;
    }

    &--hanko {
      img {
        max-height: $size * 5 + 4px;

        &:last-child {
          max-width: $size * 6;
          margin-left: $size * 2;
        }

        &:first-child {
          margin-left: 0;
          width: calc(100% - #{$size} * 8);
        }
      }
    }
  }

  &__blockchain {
    display: flex;
    margin-top: $size * 2;
    padding-top: $size * 2;
    border-top: 1px solid $gray-dark;
  }

  &__token {
    height: $size * 4;
    max-width: $size * 4;
    min-width: $size * 4;
    margin-right: $size;

    .icon {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-weight: 600;
    font-size: $size + 4px;
  }

  &__number {
    color: $green;
    font-size: $size + 4px;
    margin-top: $size / 2;
  }

  &__modal {
    left: 0;
    bottom: $size * 4;
    width: 100%;
    max-width: $size * 100;
    padding: $size * 3;
    position: absolute;
    border-radius: $border-radius;
    background-color: $white;
    border: 1px solid $yellow;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    display: none;

    &--active {
      display: block;
    }
  }

  &__close {
    top: $size;
    right: $size;
    width: $size * 2;
    height: $size * 2;
    cursor: pointer;
    position: absolute;

    &:after,
    &:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      margin: auto;
      position: absolute;
      height: 2px;
      background-color: $red;
      transition: 0.3s;
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:hover {
      &:after,
      &:before {
        background-color: $theme-color;
      }
    }
  }

  &__link {
    color: $gray-pale;
    font-weight: 600;
    text-decoration: underline;
    transition: 0.3s;

    &:hover {
      color: $theme-color;
    }
  }

  &__banner {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    margin: auto;
    padding: $size * 2;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__side {
    overflow-wrap: break-word;

    @media (min-width: 960px) {
      flex-direction: row;
    }

    @media (max-width: 956px) {
      display: grid;
      grid-column-gap: $size * 2;
      grid-auto-flow: column;
      height: calc(100vh - #{$size} * 22);
      overflow-y: auto;
      overflow-x: hidden;

      #{$r}__section {
        min-width: 65vw;
        max-width: 65vw;
      }
    }

    #{$r}--bg-yellow {
      @media (max-width: 956px) {
        background-color: rgba(0, 0, 0, 0);
      }
    }

    &--compare {
      gap: 16px;
      display: grid;
      grid-template-columns: calc(50% - 8px) calc(50% - 8px);

      #{$r}__section {
        min-width: auto;
        max-width: none;

        &[class*='bg-yellow'] {
          background-color: $yellow-pale;
        }
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;

    &--avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $size * 8;
      min-width: $size * 8;
      max-width: $size * 8;
      font-size: 14px;
      font-weight: 600;
      overflow: hidden;
      color: $theme-color;
      border-radius: 100%;
      background-color: $white;
      text-transform: uppercase;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
      }
    }

    &--name {
      margin-left: $size;
      position: relative;

      .icon--verified-profile {
        left: 0;
        bottom: calc(100% + #{$size} / 2);
        max-height: none;
        font-size: $size * 3;
        position: absolute;
      }
    }

    &--group {
      padding-left: $size * 3;

      #{$r}__user--avatar {
        height: $size * 5;
        min-width: $size * 5;
        max-width: $size * 5;
        margin-left: -$size * 2;
      }
    }
  }

  // modifier
  //---------------------------------------------------------
  &--align-center {
    align-items: center;
  }

  &--justify-between {
    justify-content: space-between;
  }

  &--bg-yellow {
    background-color: $yellow-pale;
  }

  &--border-radius {
    border-radius: $border-radius * 4;
  }

  &--border-red {
    border: 1px solid $red;
  }

  &--border-green {
    border: 1px solid $green;
  }
}

// influence
//-----------------------------------------------------------
