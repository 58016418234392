/* reset
---------------------------------------------------------------*/
html .l-body {
  overflow: hidden;
  text-size-adjust: none;
}

body {
  color: $body-color;
  font-size: 14px;
  line-height: 1.4;
  font-family: 'Arial', 'HelveticaNeueCyr', sans-serif;
}

body .l-body {
  margin: 0;
  overflow: hidden;
}

body {
  color: $body-color;
  font-size: 14px;
  line-height: 1.4;
  font-family: 'Arial', 'HelveticaNeueCyr', sans-serif;
}

.l-body {
  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  :focus {
    outline: 0;
  }

  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  ::-ms-clear {
    display: none;
  }

  ::-ms-expand {
    display: none;
  }

  table {
    width: 100%;
    border-spacing: 0;
    table-layout: auto;
    border-collapse: collapse;
  }

  td {
    padding: 0;
    vertical-align: top;
  }

  b {
    font-weight: 600;
  }

  [href] {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  [href^='tel'] {
    @media (min-width: 768px) {
      pointer-events: none;
    }
  }

  :-moz-placeholder {
    color: $placeholder;
  }

  :-ms-input-placeholder {
    color: $placeholder;
  }

  ::-webkit-input-placeholder {
    color: $placeholder;
  }
}

input,
select,
textarea {
  border: 0;
  margin: 0;
  width: 100%;
  color: inherit;
  border-radius: 0;
  box-shadow: none;
  font-size: inherit;
  font-family: inherit;
  vertical-align: bottom;
  box-sizing: border-box;
  -webkit-appearance: none;
  background-color: rgba(0, 0, 0, 0);

  &[hidden] {
    display: none;
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: initial;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: $body-color;
    -webkit-box-shadow: 0 0 0 40px white inset;
  }
}

button,
[type='reset'],
[type='button'],
[type='submit'] {
  border: 0;
  padding: 0;
  font: inherit;
  cursor: pointer;
  appearance: none;
  user-select: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: bottom;
  background-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea {
  resize: none;
}

svg {
  width: 1em;
  height: 1em;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  user-select: none;
  fill: currentColor;
  pointer-events: none;
  display: inline-flex;
}

img {
  flex: none;
  max-width: 100%;
  max-height: 100%;
  vertical-align: bottom;
}
