// base
// ------------------------------------------------------------
@import '_vars.scss';
@import '_fonts.scss';
@import '_reset.scss';
@import '_mixins.scss';

.l-body {
  // layouts
  // ------------------------------------------------------------
  @import 'l-app.scss';
  @import 'l-row.scss';
  @import 'l-main.scss';
  @import 'l-aside.scss';
  @import 'l-header.scss';
  @import 'l-sidebar.scss';
  @import 'l-section.scss';
  @import 'l-preview.scss';
  @import 'l-container.scss';

  // components
  // ------------------------------------------------------------
  @import 'c-qr.scss';
  @import 'c-nav.scss';
  @import 'c-add.scss';
  @import 'c-tab.scss';
  @import 'c-logo.scss';
  @import 'c-line.scss';
  @import 'c-menu.scss';
  @import 'c-bell.scss';
  @import 'c-card.scss';
  @import 'c-user.scss';
  @import 'c-more.scss';
  @import 'c-flag.scss';
  @import 'c-form.scss';
  @import 'c-move.scss';
  @import 'c-edit.scss';
  @import 'c-crumb.scss';
  @import 'c-badge.scss';
  @import 'c-order.scss';
  @import 'c-usage.scss';
  @import 'c-alert.scss';
  @import 'c-radio.scss';
  @import 'c-input.scss';
  @import 'c-token.scss';
  @import 'c-title.scss';
  @import 'c-photo.scss';
  @import 'c-setup.scss';
  @import 'c-panel.scss';
  @import 'c-modal.scss';
  @import 'c-group.scss';
  @import 'c-loader.scss';
  @import 'c-switch.scss';
  @import 'c-letter.scss';
  @import 'c-burger.scss';
  @import 'c-editor.scss';
  @import 'c-select.scss';
  @import 'c-upload.scss';
  @import 'c-button.scss';
  @import 'c-action.scss';
  @import 'c-search.scss';
  @import 'c-online.scss';
  @import 'c-manage.scss';
  @import 'c-pricing.scss';
  @import 'c-history.scss';
  @import 'c-keyword.scss';
  @import 'c-tooltip.scss';
  @import 'c-country.scss';
  @import 'c-library.scss';
  @import 'c-message.scss';
  @import 'c-document.scss';
  @import 'c-fieldset.scss';
  @import 'c-security.scss';
  @import 'c-checkbox.scss';
  @import 'c-textarea.scss';
  @import 'c-dropdown.scss';
  @import 'c-template.scss';
  @import 'c-skeleton.scss';
  @import 'c-signature.scss';
  @import 'c-attachment.scss';
  @import 'c-description.scss';
  @import 'c-employmenеt.scss';
  @import 'c-acknowledge.scss';
  @import 'c-verification.scss';
  @import 'c-registration.scss';

  // helpers
  // ------------------------------------------------------------
  @import 'h-сlasses.scss';
}
