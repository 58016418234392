.overlayBase {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(112, 112, 112, 0.47);
  z-index: 1000;
}

.overlayAfterOpen {
  animation: overlay-fade-enter 300ms both ease-in;
}

.overlayBeforeClose {
  animation: overlay-fade-leave 300ms both ease-out;
}

.contentBase {
  outline: 0;
}

.contentAfterOpen {
  animation: content-zoom-enter 300ms both cubic-bezier(0.4, 0, 0, 1.5);
}

.contentBeforeClose {
  animation: content-zoom-leave 300ms both;
}

.closeBtn {
  display: none;
  font-size: 40px;
  line-height: 1;
  position: absolute;
  padding: 0 12px;
  top: 0;
  right: 0;
}

@media (max-width: 600px) {
  .overlayBase {
    align-items: normal;
    justify-content: normal;
  }

  .closeBtn {
    display: block;
  }
}

@keyframes overlay-fade-enter {
  0% {
    opacity: 0;
  }
}

@keyframes overlay-fade-leave {
  100% {
    opacity: 0;
  }
}

@keyframes content-zoom-enter {
  0% {
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes content-zoom-leave {
  100% {
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
