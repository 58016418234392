body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

anita {
  font-family: 'Anita', monospace;
}

silently {
  font-family: 'Silently', monospace;
}

amorbetia {
  font-family: 'Amorbetia', monospace;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

#root .dzu-dropzone {
  border: none;
  overflow: visible;
  min-height: 200px;
}

#root .dzu-previewContainer {
  border: none;
}

#root .dzu-inputLabel {
  position: unset;
  font-size: unset;
}
