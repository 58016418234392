/* .l-row
-------------------------------------------------------------*/
$r: '.l-row';

// base
//-----------------------------------------------------------
#{$r} {
  margin-top: $size * 4;

  &:first-child {
    margin-top: 0;
  }

  // element
  //---------------------------------------------------------

  // modifiers
  //---------------------------------------------------------
  &--flex {
    display: flex;
  }

  &--flex-wrap {
    flex-wrap: wrap;
  }

  &--align-center {
    align-items: center;
  }

  &--mt-16 {
    margin-top: $size * 2;
  }

  &--mt-24 {
    margin-top: $size * 3;
  }

  &--mt-64 {
    margin-top: $size * 4;

    @media (min-width: 960px) {
      margin-top: $size * 8;
    }
  }
}

// influence
//-----------------------------------------------------------
