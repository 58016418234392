// Arial
//-----------------------------------------------------------
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'Arial';
  src: url('../fonts/Arial__400.woff') format('woff');
}

@font-face {
  font-weight: 400;
  font-style: italic;
  font-family: 'Arial';
  src: url('../fonts/Arial__400-italic.woff') format('woff');
}

@font-face {
  font-weight: 600;
  font-style: normal;
  font-family: 'Arial';
  src: url('../fonts/Arial__600.woff') format('woff');
}

@font-face {
  font-weight: 600;
  font-style: italic;
  font-family: 'Arial';
  src: url('../fonts/Arial__600-italic.woff') format('woff');
}

// HelveticaNeue
//-----------------------------------------------------------
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeue__400.woff') format('woff');
}

@font-face {
  font-weight: 600;
  font-style: normal;
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeue__600.woff') format('woff');
}

// HelveticaNeue-Condensed
//-----------------------------------------------------------
@font-face {
  font-weight: 900;
  font-style: normal;
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeue__900-condensed.woff') format('woff');
}

// Password
//-----------------------------------------------------------
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'Password';
  src: url('../fonts/Password__400.woff') format('woff');
}

// Otsutome (Hanko)
//-----------------------------------------------------------
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'Otsutome';
  src: url('../fonts/Otsutome__400.woff') format('woff');
}

// NotoSansJP
//-----------------------------------------------------------
@font-face {
  font-weight: 100;
  font-style: normal;
  font-family: 'NotoSansJP';
  src: url('../fonts/NotoSansJP__100.otf') format('opentype');
  unicode-range: U+4E00 – 9fbf, U+3040 – 309f, U+30A0 – 30ff;
}

@font-face {
  font-weight: 300;
  font-style: normal;
  font-family: 'NotoSansJP';
  src: url('../fonts/NotoSansJP__300.otf') format('opentype');
  unicode-range: U+4E00 – 9fbf, U+3040 – 309f, U+30A0 – 30ff;
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'NotoSansJP';
  src: url('../fonts/NotoSansJP__400.otf') format('opentype');
  unicode-range: U+4E00 – 9fbf, U+3040 – 309f, U+30A0 – 30ff;
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: 'NotoSansJP';
  src: url('../fonts/NotoSansJP__500.otf') format('opentype');
  unicode-range: U+4E00 – 9fbf, U+3040 – 309f, U+30A0 – 30ff;
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: 'NotoSansJP';
  src: url('../fonts/NotoSansJP__700.otf') format('opentype');
  unicode-range: U+4E00 – 9fbf, U+3040 – 309f, U+30A0 – 30ff;
}
