.container :global .react-tagsinput {
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #e2e2e2;
  transition: border-color 0.3s;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.container :global .react-tagsinput--focused {
  border-color: #4d6982;
}

.container :global .react-tagsinput-tag {
  background-color: #fff3cc;
  border-radius: 14px;
  color: #333;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 17px 5px 9px;
  position: relative;
  line-height: normal;
}

.container :global .react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  width: 9px;
  height: 9px;
  cursor: pointer;
  position: fixed;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  position: absolute;
  right: 5px;
  top: 9px;
}

.container :global .react-tagsinput-tag a::before,
.container :global .react-tagsinput-tag a::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  margin: auto;
  position: absolute;
  height: 1px;
  background-color: #333;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  box-sizing: border-box;
  transform: rotate(-45deg);
}

.container :global .react-tagsinput-tag a::after {
  transform: rotate(45deg);
}

.container :global .react-tagsinput-tag a:hover:before,
.container :global .react-tagsinput-tag a:hover:after {
  background-color: #d57872;
}

.container :global .react-tagsinput-input {
  background: transparent;
  border: 0;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  padding: 5px;
  width: 100%;
}
