.c-form__checkout .StripeElement {
  align-items: center;
  background-color: white;
  border: 2px solid #e2e2e2;
  border-radius: 4px;
  display: flex;
  height: 40px;
  padding: 0 8px;
  transition: border-color 0.3s;
}

.c-form__checkout .StripeElement > * {
  flex: 1;
}

.c-form__checkout .StripeElement--focus {
  border-color: #4d6982;
}

.c-form__checkout .StripeElement--invalid {
  border-color: #d57872;
}

.c-form__checkout .c-form__custom-error {
  color: #ff6060;
  font-size: 12px;
  margin: 2px 0 -4px;
}
