.previewContainer {
  justify-content: center;
}

.previewImage {
  border-radius: 0;
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: 400px;
  max-width: 240px;
}
