@font-face {
  font-family: 'Helvetica Neue';
  src: url('HelveticaNeue.eot');
  src: url('HelveticaNeue.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeue.woff2') format('woff2'), url('HelveticaNeue.woff') format('woff'),
    url('HelveticaNeue.ttf') format('truetype'),
    url('HelveticaNeue.svg#HelveticaNeue') format('svg');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('HelveticaNeue-Bold.eot');
  src: url('HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeue-Bold.woff2') format('woff2'), url('HelveticaNeue-Bold.woff') format('woff'),
    url('HelveticaNeue-Bold.ttf') format('truetype'),
    url('HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('HelveticaNeue-Italic.eot');
  src: url('HelveticaNeue-Italic.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeue-Italic.woff2') format('woff2'),
    url('HelveticaNeue-Italic.woff') format('woff'),
    url('HelveticaNeue-Italic.ttf') format('truetype'),
    url('HelveticaNeue-Italic.svg#HelveticaNeue-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('HelveticaNeue-BoldItalic.eot');
  src: url('HelveticaNeue-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeue-BoldItalic.woff2') format('woff2'),
    url('HelveticaNeue-BoldItalic.woff') format('woff'),
    url('HelveticaNeue-BoldItalic.ttf') format('truetype'),
    url('HelveticaNeue-BoldItalic.svg#HelveticaNeue-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('HelveticaNeue-Light.eot');
  src: url('HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeue-Light.woff2') format('woff2'), url('HelveticaNeue-Light.woff') format('woff'),
    url('HelveticaNeue-Light.ttf') format('truetype'),
    url('HelveticaNeue-Light.svg#HelveticaNeue-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('HelveticaNeue-LightItalic.eot');
  src: url('HelveticaNeue-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeue-LightItalic.woff2') format('woff2'),
    url('HelveticaNeue-LightItalic.woff') format('woff'),
    url('HelveticaNeue-LightItalic.ttf') format('truetype'),
    url('HelveticaNeue-LightItalic.svg#HelveticaNeue-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('HelveticaNeue-CondensedBold.eot');
  src: url('HelveticaNeue-CondensedBold.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeue-CondensedBold.woff2') format('woff2'),
    url('HelveticaNeue-CondensedBold.woff') format('woff'),
    url('HelveticaNeue-CondensedBold.ttf') format('truetype'),
    url('HelveticaNeue-CondensedBold.svg#HelveticaNeue-CondensedBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: 'Anita';
  src: url('AnitaScript.otf');
  src: url('AnitaScript.otf'), url('AnitaScript.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Silently';
  src: url('Silently.otf');
  src: url('Silently.otf'), url('Silently.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Amorbetia';
  src: url('Amorbetia.otf');
  src: url('Amorbetia.otf'), url('Amorbetia.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url('NotoSansJP-Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'NotoSerifJP';
  src: url('NotoSerifJP-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
